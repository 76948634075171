<script>
import AppNotificationItem from '/~/components/app/app-notification-item.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import { useNotifications } from '/~/composables/notifications'

export default {
  name: 'rec-notifications',
  components: {
    EwCatalogProcessor,
    AppNotificationItem,
    BaseButton,
    BaseInput,
  },
  setup() {
    const { notificationsProcessor, hasUnreadNotifications } =
      useNotifications()

    return {
      notificationsProcessor,
      hasUnreadNotifications,
    }
  },
  data() {
    return {
      search: '',
    }
  },
  methods: {
    markAllAsRead() {
      this.notificationsProcessor.hits.forEach((notification) => {
        notification.markAsRead()
      })
    },
    clearAll() {
      this.notificationsProcessor.clearAll()
    },
  },
}
</script>

<template>
  <div class="mx-auto flex max-w-2xl flex-col p-2.5 pt-5 sm:p-5">
    <div class="flex">
      <span class="text-xl font-bold">Notifications</span>
      <div class="ml-auto flex items-center space-x-5">
        <base-button
          look="link"
          :disabled="
            notificationsProcessor.isEmpty || notificationsProcessor.isLoading
          "
          @click="clearAll"
        >
          <span
            class="font-bold text-blue-400"
            :class="{
              'text-gray-300': notificationsProcessor.isEmpty,
            }"
          >
            Clear All
          </span>
        </base-button>
        <base-button
          look="link"
          :disabled="
            !hasUnreadNotifications || notificationsProcessor.isLoading
          "
          @click="markAllAsRead"
        >
          <span
            class="font-bold text-blue-400"
            :class="{
              'text-gray-300': !hasUnreadNotifications,
            }"
          >
            Mark all as read
          </span>
        </base-button>
      </div>
    </div>
    <div class="my-[15px]">
      <base-input
        v-model="search"
        icon="plain/search"
        placeholder="Search notifications by user, type or keyword"
        name="search"
        icon-plain
        look="recroom"
        nolabel
      />
    </div>
    <div class="mt-5">
      <ew-catalog-processor
        :processor="notificationsProcessor"
        :columns="1"
        use-overlay-loader
      >
        <template #empty>
          <div>No notifications</div>
        </template>

        <template #tile="{ tile }">
          <app-notification-item
            :item="tile"
            :feed="notificationsProcessor"
            class="-my-2.5 hover:bg-gray-50"
          />
        </template>
      </ew-catalog-processor>
    </div>
  </div>
</template>
